import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Grid, IconButton, Box } from '@material-ui/core';
import { ReactMultiEmail } from 'react-multi-email';
import 'react-multi-email/dist/style.css';
import StepNavigation from './stepNavigation';
import MuiAlert from '@material-ui/lab/Alert';
import { API } from 'aws-amplify';
import Loading from '../Loading';
import accountAPI from '../../flowtrace-api/Account/propertyActions';
import webstoreLogo from '../Common/webstoreLogo';
const maxWidth = { style: { maxWidth: "800px" } };

const createAlert = (type, message, alertHeader = null) => {
  return (
    <MuiAlert key={"alertMessage" + message.replace(/\s+/g, '')} severity={type} className="pt-1 mt-1 mb-4 pb-1" {...maxWidth}>
      {alertHeader ? <span><strong className="d-block">{alertHeader}</strong><br/></span> : null}

      {message}
    </MuiAlert>
  );
};


const wizardSteps = (seatCount) => {
  const trialSteps =
    <ul>
      <li>Widget Customization Options</li>
      <li>Custom Meeting Cost Configuration</li>
      <li>Custom Meeting Policy</li>
      <li>Custom Cost Alert Configuration</li>
      {seatCount ? <li>Included in your subscription: {seatCount} user(s)</li> : null}
    </ul>;

  const alertHeader = "By subscribing to Flowtrace Team plan, you get:";
  return (<MuiAlert className="mb-4" severity="info" {...maxWidth}>
        <div className="d-flex align-items-center align-content-center">
          <span>
            <strong className="d-block">{alertHeader}</strong>
            <br/>
            {trialSteps}
          </span>
        </div>
      </MuiAlert>);
};

class OnboardingTrial extends Component {
  constructor(props) {
    super(props);

    const a = this.props.flowtraceUser.account ? this.props.flowtraceUser.account : null;
    const chromeExtension = a && a.props.chromeExtension ? a.props.chromeExtension : {}; // This should not happen as user should not be on this screen
    if(!chromeExtension || !chromeExtension.seats)
      chromeExtension.seats = [this.props.flowtraceUser.email];
    this.state = {loading: false, chromeExtension };
  }

  async createCheckoutPortal() {
    this.setState({ loading: true, apiError:null });
    try {
      const newChromeExtension = this.state.chromeExtension;
      // Get previous costConfiguration
      const a = this.props.flowtraceUser.account ? this.props.flowtraceUser.account : null;
      const chromeExtension = a.props.chromeExtension ? a.props.chromeExtension : null;
      // Remove existing properties before adding a new one
      if(chromeExtension)
        await accountAPI.deleteProperty(this.props.flowtraceUser.account.account_id, {chromeExtension});
      const addedResponse = await accountAPI.addProperty(this.props.flowtraceUser.account.account_id, {chromeExtension:newChromeExtension});
      if(!addedResponse.success){
        return this.setState({ loading: false });
      }

      const name = 'slackintegration';
      const path = '/stripe/create-checkout-session/trial-onboarding';
      const init = { headers: { "Content-Type": "application/json" }, response: true };

      const response = await API.get(name, path, init);
      if (response.data.url)
        window.location.href = response.data.url;
      else
        return this.setState({ loading: false, apiError:response.data.error });
    }
    catch (e) {
      console.error(e);
      return this.setState({ loading: false, apiError: "Couldn't connect to Flowtrace. Please contact support for help." });
    }
  }

  async completeRedirect() {
    await this.props.flowtraceUser.reload();
    this.props.history.push("/Account/Settings");
    this.props.onUpdate("onboardingComplete");
  }

  render() {
    if(!this.props.isActive) return null;
    if(this.state && this.state.loading) return <Loading text="Please wait while we are creating a secure payment session and forward you to our payment provider..."/>;
    if(this.props && this.props.flowtraceUser.reloading) return <Loading text="Preparing your account."/>;
    const s = this.props.settings;

    let nextAction = () => this.createCheckoutPortal.bind(this);
    let disableNext = false;
    let nextText = "Subscribe";
    let nextSuggestionText = "Flowtrace subscription plan. Type email addresses with space or comma separated. You can contact our customer support for complimentary 1 month 100% discount.";
    let message = null;
    let stepTitle = "Last thing: Add your colleagues who need help with meeting overload!"
    let complete = false;

    if(s.stripe && s.stripe.session_id) {
      const openInNewTab = (url) => { window.open(url, "_blank", "noreferrer"); };
      const webstoreUrl = "https://chrome.google.com/webstore/detail/bjfhkjgmnlabpopcmgagbdgjfelndpfj";
      message = <div>
        { createAlert("success", "It usually takes less than a minute to all settings to update. You should got to your Google Calendar, make sure you are logged in to Flowtrace extension, and refresh the browser for new configuration to appear.", "Your account has been upgraded to Team plan!") }
        { createAlert("info", "Ask your team members to install the Meeting Cost for Google Calendar with this link: https://chrome.google.com/webstore/detail/bjfhkjgmnlabpopcmgagbdgjfelndpfj", "Don't forget to invite your team!") }
          <IconButton id={"getFromWebStore"} onClick={() => openInNewTab(webstoreUrl)}>
            <Box component="img" src={webstoreLogo.src} alt={"Get it from Google Chrome Web Store"} sx={{ height: "50px", width: "auto" }} />
          </IconButton>
        </div>;
      nextSuggestionText = "Click COMPLETE to end the onboarding! You can login to Flowtrace any time to update your settings.";
      nextText = "Complete";
      stepTitle = "Onboarding Complete";
      complete = true;
      nextAction = () => this.completeRedirect.bind(this);
    } else if(s.stripe && !s.stripe.session_id) {
      message = createAlert("warning", "Something went wrong and we could not start the subscription.");
      nextSuggestionText = "If this keeps happening, please contact us or try again later.";
    } else if(this.state.chromeExtension.seats.length) {
      nextSuggestionText = "Flowtrace subscription comes fully charged.";
    } else {
      disableNext = true;
    }

    return (
      <StepNavigation stepTitle={stepTitle} {...this.props} nextAction={nextAction} backDisabled={complete} nextDisabled={disableNext} nextText={nextText}>
        <div className="mt-2" />
        <Grid container spacing={4} direction="column" justifyContent="center" alignItems="stretch">
          {!complete ?
          <Grid item xs={12}>
            <div {...maxWidth}>
              <p>To have biggest impact with your meeting culture, we recommend you invite all your immediate colleagues to use Flowtrace.</p>
              <p>You can add more colleagues below (type or copy paste):</p>
              <ReactMultiEmail
                placeholder={<span style={{ color: '#1890ff' }}>Type of copy paste colleague email addresses here</span>}
                emails={this.state.chromeExtension.seats}
                onChange={(emails) => { this.setState({chromeExtension:{...this.state.chromeExtension, seats:emails}}); }}
                autoFocus={false}
                getLabel={(email, index, removeEmail) => {
                  return (
                    <div data-tag key={index}>
                      <div data-tag-item>{email}</div>
                      <span data-tag-handle onClick={() => removeEmail(index)}>
                        X
                      </span>
                    </div>
                  );
                }}
              />
            </div>
          </Grid>
          :null}
          <Grid item xs={12}>
            {message ? message : wizardSteps(this.state.chromeExtension.seats.length) }
          </Grid>
          { this.state.apiError ?
            <Grid item xs={12}>
              {createAlert("warning", this.state.apiError)}
            </Grid>
          : null }
          <Grid item xs={12}>
            {nextSuggestionText}
          </Grid>
        </Grid>

      </StepNavigation>
    );

  }
}

export default withRouter(OnboardingTrial);
