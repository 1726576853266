import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import StepNavigation from './stepNavigation';
import MuiAlert from '@material-ui/lab/Alert';
import GoogleWorkspace from '../Configuration/Integration/IntegrationConfiguration/googleWorkspace.js';
import { withRouter } from 'react-router-dom';


const maxWidth = { style: { maxWidth: "800px" } };
const wizardSteps =
  <MuiAlert className="mb-4" severity="info" {...maxWidth}>
    <div className="d-flex align-items-center align-content-center">
      <span>
        <strong className="d-block">Next up: Complete the onboarding</strong>
        <br/>
          <ul>
            <li>Configure Flowtrace to you business cost base context</li>
            <li>You will get the first insights into your company calendar in the coming minutes</li>
            <li>Complete analytics for your calendar will be available in the coming 24 hours</li>
            <li>For team structure and advanced analaytics filters: Start a subcription with our Team plan</li>
          </ul>
      </span>
    </div>
  </MuiAlert>;

class Onboarding extends Component {
  constructor(props) {
    super(props);
    this.state = { googleInstall:false };
  }

  onInstall(success) {
    console.log("onInstall:", success)
    if(success && this.state.googleInstall !== success)
      this.setState({googleInstall:true});
  }

  render() {
    if(!this.props.isActive) return null;
    const needInstall = <h5 className="text-weight-bold">Flowtrace Meeting Analytics for Google Workspace integration:</h5>;
    const installDone = <h5 className="text-weight-bold">Flowtrace Meeting Analytics for Google Workspace integrated!</h5>;
    return (
      <StepNavigation stepTitle="Verify Meeting Analytics Install" {...this.props} nextDisabled={!this.state.googleInstall} >
        <div className="mt-2" />
        {this.state.googleInstall?installDone:needInstall}
        <Grid container spacing={4} direction="column" justifyContent="center" alignItems="stretch">
          <Grid item xs={12}>
            {this.props.currentStep === 3 ?
            <GoogleWorkspace open={true} onboarding={true} onInstall={this.onInstall.bind(this)} flowtraceUser={this.props.flowtraceUser}/>
            : null }
          </Grid>
          {this.state.googleInstall?<Grid item xs={12}>{wizardSteps}</Grid>:null}
        </Grid>
      </StepNavigation>
    );

  }
}

export default withRouter(Onboarding);
